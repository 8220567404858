<section class="subscribe">
    <h1 class="subscribe-title">Abonnement</h1>
    <p class="subscribe-text">En s'abonnant à l'offre <span class="important">premium</span> de limon, vous avez accès à tout les films de notre catalogue en streaming, disponible en Haute définition (peut aller jusqu'à la 4K).</p>
    <p class="subscribe-text">Le paiement est entièrement sécurisé et une facture vous sera envoyé par mail (l'adresse mail lié au compte)</p>
    <h2 class="subscribe-text imp">L'offre <span class="important">premium</span> est au prix de <span class="important">46.99€</span> par an</h2>
    <div class="subscribe-button">
        <button routerLink="/" class="subscribe-button-back">Revenir sur le site</button>
        <button class="subscribe-button-proceed" (click)="buy()" >S'abonner</button>
    </div>
</section>
<app-checkout *ngIf="isCheck" [(isCheck)]="isCheck" [amount]="amount" [title]="'Abonnement PREMIUM'" [direction]="direction"></app-checkout>

