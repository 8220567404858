<section class="search">
    <div class="search-bar">
        <div class="search-bar-container">
            <input type="text" placeholder="Rechercher un film" aria-label="rechercher un film" (keyup.enter)="OnSearch()"  #search required>
            <button class="search-cancel" aria-label="Annuler la saisie" (click)="onCancelSearch()" *ngIf="isSearch">
                <img src="../../assets/icons/cancel-icon.svg" alt="">
            </button>
        </div>
        <button id="search" (click)="OnSearch()"><img src="../../assets/icons/search.svg" alt="rechercher" aria-label="Rechercher"></button>
    </div>
</section>
