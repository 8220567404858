<section class="connect">
    <form [formGroup]="userForm" (ngSubmit)="onSubmitForm()">
        <button type="button" class="connect-return" routerLink="/">Revenir à l'accueil</button>
        <h1 class="connect-title">{{ isLogin ? 'Connexion' : 'Inscription' }}</h1>
        <div class="connect-inputs">
            <div *ngIf="userForm.get('email')?.errors?.email  || userForm.get('password').errors?.minlength" class="connect-error">
                <p *ngIf="userForm.get('email')?.errors?.email">Veuillez renseigner une adresse email valide</p>
                <p *ngIf="userForm.get('password')?.errors?.minlength">Le mot de passe doit contenir au moins 4 caractères</p>
            </div>
            <div *ngIf="errors.length > 0" class="connect-error">
                <p *ngFor="let error of errors">{{ errors }}</p>
            </div>
            <div class="input-row">
                <div class="input-cont">
                    <label for="username" *ngIf="!isLogin">Nom d'utilisateur</label>
                    <input type="text" id="username" placeholder="Nom d'utilisateur" formControlName="fullname" aria-label="Entrer un nom d'utilisateur" class="connect-user" *ngIf="!isLogin">
                </div>
                <div class="input-cont">
                    <label for="birthday" *ngIf="!isLogin">Date de naissance</label>
                    <input type="date" id="birthday" aria-label="Entrer votre date de naissance" formControlName="birthday" class="connect-birth" *ngIf="!isLogin">
                </div>
            </div>

            <div class="input-cont">
                <label for="mail">Adresse Mail</label>
                <input *ngIf="isLogin" type="mail" id="mail" placeholder="adresse@mail.com" formControlName="username"  aria-label="Entrer un identifiant" class="connect-id">
                <input *ngIf="!isLogin" type="mail" id="mail" placeholder="adresse@mail.com" formControlName="email"  aria-label="Entrer un identifiant" class="connect-id">
            </div>
            <div class="input-cont">
                <label for="pswd">Mot de passe</label>
                <input type="password" id="pswd" placeholder="mot de passe" formControlName="password" aria-label="Entrer un mot de passe" class="connect-pass">
            </div>
        </div>
        <div class="connect-link">
            <a routerLink="{{ isLogin ? '/signin' : '/login' }}" class="connect-other" type="submit">{{ isLogin ? 'Vous n\'avez pas de compte ? Inscrivez-vous !' : 'Vous avez déjà un compte ?' }}</a>
            <button class="connect-button" type="submit" [disabled]="userForm.invalid">{{ isLogin ? 'Se Connecter' : 'S\'Inscrire' }}</button>
        </div>
    </form>
</section>

