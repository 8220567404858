<header>
    <div class="burger-menu" *ngIf="isMobile" (click)="onAppear()">
        <span class="slice"></span>
        <span class="slice"></span>
        <span class="slice"></span>
    </div>
    
    <section class="logo proper-touch" *ngIf="!isMobile">
        <a class="logo-container" routerLink="/" (click)="onReset()">
            <img src="../../assets/logo.svg" alt="logo">
        </a>
    </section>
    <section class="maxi proper-touch" *ngIf="isMobile" (click)="onHide()">
        <a class="logo-container mobile-logo" routerLink="/" (click)="onReset()">
            <img src="../../assets/shdow.svg" alt="logo">
        </a>
    </section>
    <app-searchbar *ngIf="!isMobile"></app-searchbar>
    <section class="nav">
        <div *ngIf="!isMobile && isAuth && !isSub">
            <button routerLink="/subscription" class="nav-subscribe">S'abonner</button>
        </div>
        <div *ngIf="!isMobile">
            <div class="nav-list">
                <a routerLink="/search">catalogue</a>
            </div>
        </div>
        <div *ngIf="isAuth" (click)="onHide()">
            <a class="nav-profil" routerLink="/profil" title="profil">
                <img *ngIf="mail" src="{{ 'https://www.gravatar.com/avatar/' + mail + '?s=200&d=https%3A%2F%2Flimon.app-tricycle.com%2Fassets%2Fprofil-notfound.png' }}" alt="Image de Profil" class="nav-profil-pic">
                <img src="../../assets/icons/badge.png" alt="badge" tabindex="-1" class="nav-profil-badge" *ngIf="isSub">
            </a>
        </div>
        <div *ngIf="!isMobile && isAuth">
            <a class="nav-watchlist" routerLink="/watchlist" title="Watchlist">
                <svg xmlns="http://www.w3.org/2000/svg" width="86.589" height="132.214" viewBox="0 0 86.589 132.214">
                    <path d="M166.6,0H96.668a8.335,8.335,0,0,0-8.326,8.326V127.219a5,5,0,0,0,7.621,4.251l35.683-22.03,35.678,21.87a5,5,0,0,0,7.607-4.258V8.326A8.335,8.335,0,0,0,166.6,0ZM164.94,118.128,134.249,99.314a5,5,0,0,0-5.237.008L98.333,118.263V9.991H164.94Z" transform="translate(-88.342)"/>
                </svg>
            </a>
        </div>
        <div *ngIf="!isMobile && isAuth">
            <a class="nav-history" routerLink="/history" title="Historique">
                <svg xmlns="http://www.w3.org/2000/svg" width="101.503" height="87.002" viewBox="0 0 101.503 87.002">
                    <g data-name="history" transform="translate(-621.392 3746.521)">
                      <path data-name="out"  d="M234.667,138.667v29L259.492,182.4l4.176-7.047-20.3-12.035V138.667Z" transform="translate(437.476 -3864.665)"/>
                      <path data-name="in"  d="M57.977,32A43.468,43.468,0,0,0,14.5,75.5H0L18.826,94.327l.338.7L38.668,75.5h-14.5A33.913,33.913,0,1,1,34.1,99.4l-6.839,6.839A43.49,43.49,0,1,0,57.977,32Z" transform="translate(621.392 -3778.521)"/>
                    </g>
                </svg>
            </a>
        </div>
        <div *ngIf="isMobile" routerLink="/search" class="proper-touch" (click)="onHide()">
            <div class="nav-search">
                <svg xmlns="http://www.w3.org/2000/svg" width="408.649" height="423.27" viewBox="0 0 408.649 423.27">
                    <path d="M403.419,385.532,302.681,280.758A170.345,170.345,0,0,0,342.774,170.9c0-94.23-76.665-170.9-170.9-170.9S.984,76.665.984,170.9s76.665,170.9,170.9,170.9a169.065,169.065,0,0,0,97.908-30.925l101.5,105.569a22.289,22.289,0,1,0,32.128-30.9ZM171.879,44.581A126.314,126.314,0,1,1,45.565,170.9,126.455,126.455,0,0,1,171.879,44.581Z" transform="translate(-0.984)"/>
                </svg>
            </div>
        </div>
        <div *ngIf="!isMobile && !isAuth">
            <div class="nav-connect">
                <button routerLink="/login">Connexion</button>
            </div>
        </div>
        <div *ngIf="!isMobile && !isAuth">
            <div class="nav-sign">
                <button routerLink="/register">Inscription</button>
            </div>
        </div>
    </section>
</header>
<nav *ngIf="isMobile" [style.transform]="onMenuOpen()">
    <a routerLink="/search" (click)="onAppear()">Catalogue</a>
    <a routerLink="/history" (click)="onAppear()" *ngIf="isAuth">Historique</a>
    <a routerLink="/watchlist" (click)="onAppear()" *ngIf="isAuth">Watchlist</a>
    <a routerLink="/subscription" (click)="onAppear()" class="subscribe" *ngIf="isAuth && !isSub">S'abonner</a>
    <a routerLink="/login" (click)="onAppear()" class="subscribe s2" *ngIf="!isAuth && !isSub">Connexion</a>
    <a routerLink="/signin" (click)="onAppear()" class="subscribe" *ngIf="!isAuth && !isSub">Inscription</a>
</nav>

<div class="backdrop" *ngIf="isMobile && isMenu" (click)="onHide()"></div>