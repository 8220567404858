<section class="note">
    <div *ngIf="isAuth">
        <p>Notez ce film !</p>
        <div class="note-select">
                <input type="radio" name="note" id="n1" [checked]="userNote === 1">
                <label for="n1" class="note-select-item" (click)="onNote(1)"><img src="../../assets/note.svg" alt="1 point"></label>
                <input type="radio" name="note" id="n2" [checked]="userNote === 2">
                <label for="n2" class="note-select-item" (click)="onNote(2)"><img src="../../assets/note.svg" alt="2 points"></label>
                <input type="radio" name="note" id="n3" [checked]="userNote === 3">
                <label for="n3" class="note-select-item" (click)="onNote(3)"><img src="../../assets/note.svg" alt="3 points"></label>  
                <input type="radio" name="note" id="n4" [checked]="userNote === 4">
                <label for="n4" class="note-select-item" (click)="onNote(4)"><img src="../../assets/note.svg" alt="4 points"></label>
                <input type="radio" name="note" id="n5" [checked]="userNote === 5">
                <label for="n5" class="note-select-item" (click)="onNote(5)"><img src="../../assets/note.svg" alt="5 points"></label>
        </div>
    </div>
    <div class="note-medium">
        <p class="note-medium-label">Note moyenne</p>
        <b class="note-medium-main"><span class="hollow" *ngIf="note == undefined">5</span><span class="colored">{{note}}</span>/5</b>
    </div>
</section>
<app-confirmation *ngIf="isConfirm" [input]="'note'"></app-confirmation>
