<app-modal *ngIf="isModal" [(isModal)]="isModal" [ID]="movieID" [subject]="'film'"></app-modal>
<section class="movie">
    <div class="movie-img">
        <img src="{{ movie?.hero_img }}" alt="" class="movie-hero" *ngIf="!isMobile">
        <img src="{{ movie?.poster_img || '../../assets/poster-notfound.webp' }}" alt="" class="movie-poster" *ngIf="isMobile">
    </div>
    <div class="detail">
        <h1 class="detail-title">{{ movie?.title }}</h1>
        <div class="detail-line detail-subinfos">
            <b class="detail-prod">{{ movie?.productor.name }} <span *ngIf="movie?.release_date !== '' && movie?.productor.name != ' ' && movie?.productor.name != ''">-</span> {{ movie?.release_date | date:'yyyy' }}</b>
            <div class="detail-category" *ngIf="movie?.category.name !== ''">{{ movie?.category.name }}</div>
        </div>
        <p class="detail-desc">{{ movie?.synopsis || 'Aucun synopsis disponible' }}</p>
        <div class="detail-line detail-pric" *ngIf="movie?.price !== ''">
                <p>Prix</p><h2 class="detail-pricing">{{ movie?.price }} €</h2>
        </div>
        <div class="detail-line">
            <button class="detail-buy" (click)="buy()" *ngIf="movie?.price !== 0 && !isPurchase && isAuth && !isAdmin"><img src="../../assets/icons/achat-icon-color.svg" alt="" class="detail-icon">  Acheter</button>
            <button class="detail-buy" routerLink="/complete/{{movieID}}" *ngIf="movie?.price == 0 && !isPurchase && isAuth && !isAdmin"><img src="../../assets/icons/achat-icon-color.svg" alt="" class="detail-icon"> Télécharger</button>
            <button class="detail-play" *ngIf="isAuth && isPremium || isPurchase" (click)="onPlay()"><img src="../../assets/icons/play-icon.svg" alt="" class="detail-icon"> Lecture</button>
            <button class="detail-play" *ngIf="isAuth && !isPremium && !isPurchase" routerLink="/subscription">S'abonner pour le visionner</button>
            <button class="detail-play" *ngIf="!isAuth" routerLink="/register">S'abonner pour le visionner</button>
            <button class="detail-subscribe" (click)="download()" *ngIf="isPurchase">Télécharger</button>
            <button class="detail-edit" (click)="onEdit()" *ngIf="isAdmin">Modifier</button>
            <button class="detail-delete" (click)="swapModal()" *ngIf="isAdmin">Supprimer</button>
        </div>
    </div>
    <button class="movie-watch" (click)="changeMark();onWatch();" *ngIf="isAuth">
        <svg xmlns="http://www.w3.org/2000/svg" width="86.589" height="132.214" viewBox="0 0 86.589 132.214" *ngIf="!isMark">
            <path  data-name="Bookmark" d="M166.6,0H96.668a8.335,8.335,0,0,0-8.326,8.326V127.219a5,5,0,0,0,7.621,4.251l35.683-22.03,35.678,21.87a5,5,0,0,0,7.607-4.258V8.326A8.335,8.335,0,0,0,166.6,0ZM164.94,118.128,134.249,99.314a5,5,0,0,0-5.237.008L98.333,118.263V9.991H164.94Z" transform="translate(-88.342)"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="173.791" height="265.364" viewBox="0 0 173.791 265.364" *ngIf="isMark">
            <path d="M5.144,264.094A10.026,10.026,0,0,1,0,255.338V16.71A16.729,16.729,0,0,1,16.71,0h140.37a16.729,16.729,0,0,1,16.71,16.71V255a10.026,10.026,0,0,1-15.267,8.546l-71.608-43.9L15.3,263.87a10.033,10.033,0,0,1-10.153.224Z"/>
        </svg>
    </button>
</section>
<app-checkout *ngIf="isCheck" [(isCheck)]="isCheck" [amount]="movie?.price" [title]="movie?.title" [id]="movieID" [direction]="direction"></app-checkout>
<section class="community">
    <section class="avis">
        <h1>Avis</h1>
        <app-review *ngFor="let review of reviews" [reviews]="review"></app-review>
        <p *ngIf="isReviewEmpty" class="community-empty">Soyez le premier à donner votre avis sur le film 😄</p>
    </section>
    <div class="wrap-func">
        <section class="note"><app-note [note]="note" [userNote]="usrNote"></app-note></section>
        <section class="write"><app-write-review></app-write-review></section>
    </div>
</section>
<app-video *ngIf="isView" [(isView)]="isView"></app-video>