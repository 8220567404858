<app-modal *ngIf="isModal" [(isModal)]="isModal" [isActual]="isActual" [ID]="id" [subject]="'compte'"></app-modal>
<section class="profil">
    <form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="onSubmitForm()">
    <div *ngIf="errors.length > 0" class="error">
        <p *ngFor="let error of errors">{{ error }}</p>
    </div>
    <section class="profil-main">
        <img *ngIf="mail" src="{{ 'https://www.gravatar.com/avatar/' + mail + '?s=200&d=https%3A%2F%2Flimon.app-tricycle.com%2Fassets%2Fprofil-notfound.png' || '../../assets/profil-notfound.png'}}" alt="Photo de profil" class="profil-main-pic">
        <div class="profil-main-infos">
            <h1 *ngIf="!isEdit">{{ user?.fullname }}</h1>
            <div *ngIf="user?.subscription?.activate"><img src="../../assets/icons/badge.png" alt="Compte premium" class="profil-main-badge">Premium</div>
        </div>  
        <div class="modify-main">
            <input type="text" formControlName="fullname" class="input-name" [ngModel]="user?.fullname" *ngIf="isEdit">
            <a *ngIf="isEdit" href="https://fr.gravatar.com/" aria-label="Changer la photo de profil" target="_blank" class="gravatar">
                <img src="assets/icons/gravatar.png" alt="">
                Modifier votre photo de profil sur Gravatar</a>
        </div>
        <div class="sup-deconnect sup2" *ngIf="!isEdit && isAdmin">
            <button class="deconnect ad-light" routerLink="/admin" type="button">Accéder à l'administration</button>
        </div>
        <img src="../../assets/icons/settings-icon.svg" alt="Paramètres" class="profil-main-settings" (click)="onOpenSettings()">
        <div *ngIf="isSettings" class="profil-main-popup">
            <a (click)="onEdit();onOpenSettings();">Modifier les informations</a>
            <a (click)="swapModal();onOpenSettings()">Supprimer le compte</a>
        </div>
    </section>
    <section class="profil-sup">
        <div>
            <h2>Adresse mail</h2>
            <p *ngIf="!isEdit">{{ user?.email }}</p>
            <input type="text" formControlName="email" [ngModel]="user?.email" *ngIf="isEdit">
        </div>
        <div>
            <h2>Date de naissance</h2>
            <p *ngIf="!isEdit">{{ user?.birthday | date}}</p>
            <input type="date" formControlName="birthday" [ngModel]="user?.birthday | date:'yyyy-MM-dd' " *ngIf="isEdit">
        </div>
        <div class="sup-deconnect" *ngIf="!isEdit">
            <button class="deconnect" (click)="logout()" type="button">Se déconnecter</button>
        </div>
    </section>
    <section class="profil-access">
        <div class="profil-access-cont" *ngIf="!isEdit && isActual">
            <button routerLink="/history" type="button">
                <img src="../../assets/icons/history-icon.svg" alt="">
            </button>
            <p>Consulter l'historique</p>
        </div>
        <div class="profil-access-cont"  *ngIf="!isEdit && isActual">
            <button routerLink="/watchlist"type="button">
                <img src="../../assets/icons/watchlist-icon.svg" class="history-icon" alt="">
            </button>
            <p>Consulter votre watchlist</p>
        </div>
        <div class="profil-access-cont" *ngIf="isActual">
            <button routerLink="/purchase" type="button">
                <img src="../../assets/icons/achat-icon.svg" class="purchase-icon" alt="">
            </button>
            <p>Voir les Films achetés</p>
        </div>
        <div class="profil-access-cont" *ngIf="!isActual">
            <button [routerLink]="['/purchase']" [queryParams]="{userId:id}" type="button">
                <img src="../../assets/icons/achat-icon.svg" class="purchase-icon" alt="">
            </button>
            <p>Voir les Films achetés</p>
        </div>
        <button class="save-edit" *ngIf="isEdit" type="submit" [disabled]="userForm.invalid">
            <img src="assets/icons/save.svg" alt="">
            Enregistrer les modifications
        </button>
    </section>
</form>
</section>
