<section class="item">
    <a class="item-img" routerLink="/detail/{{ item.id }}">
        <img src="{{ item.poster_img !== '' ? item.poster_img : 'assets/poster-notfound.png' }}" alt="poster">
    </a>
    <div class="infos">
        <h2 class="infos-title">{{ item.title }}</h2>
        <b class="infos-prod">{{ item.productor.name }} <span *ngIf="item.release_date !== ''">-</span> {{ item.release_date | date:'yyyy' }}</b>
        <div class="infos-category">{{ item.category.name }}</div>
        <p class="infos-price" *ngIf="!isBuy">{{ item.price }} €</p>
    </div>
    <div class="item-command">
        <p class="item-command-date" *ngIf="isHistory">{{ date | date: 'HH:mm, dd MMMM':'UTC+2' }}</p>
        <button class="item-command-bill" (click)="onBill()" *ngIf="isPurchase"><img src="../../assets/icons/facture-icon.png" alt="">Facture</button>
        <button class="item-command-delete" (click)="onDelete(item.id)" *ngIf="isWatch"><img src="../../assets/icons/bin-icon.svg" alt="">Supprimer</button>
        <button *ngIf="!isBuy && (isHistory || isWatch)" (click)="buy()"><img src="../../assets/icons/achat-icon.svg" alt="">Acheter</button>
        <button *ngIf="isBuy || isPurchase && (isHistory || isPurchase || isWatch)" (click)="onDl(item.id)"><img src="../../assets/icons/download-icon.svg" alt="">Télécharger</button>
    </div>
</section>
<app-checkout *ngIf="isCheck" [(isCheck)]="isCheck" [amount]="item?.price" [title]="item?.title" [id]="item?.id" [direction]="direction"></app-checkout>