<div class="rgpd" tabindex="4">
    <p class="rgpd-text">Le site utilise récupère les données du traffic les données personelles lors de l'inscription. En naviguant sur se site vous accepter de nous aidés à améliorer notre site en nous partageant vos données de traffic.</p>
    <div class="rgpd-action">
        <a routerLink="/agreement" class="rgpd-link" tabindex="4">En savoir plus</a>
        <div class="rpgd-button">
            <button tabindex="4" class="rgpd-denied" (click)="onClear();onDenied()">Refuser</button>
            <button  tabindex="4" class="rgpd-valid" (click)="onClear();onValid()">Accepter</button>
        </div>
    </div>
</div>
