<section class="admin">
    <h1>Page d'administration</h1>
    <section class="global">
        <h2>Statistiques Globales</h2>
        <div class="nb-section">
            <div class="nb-container">
                <p class="nb-container-title">Nombre de membres au total</p>
                <b class="nb-container-content">{{ stats?.total_number_user }}</b>
            </div>
            <div class="nb-container">
                <p class="nb-container-title">Nombre de ventes au total</p>
                <b class="nb-container-content">{{ stats?.total_number_sales }}</b>
            </div>
            <div class="nb-container">
                <p class="nb-container-title">Revenus totaux</p>
                <b class="nb-container-content">{{ stats?.sum_sales }} €</b>
            </div>
        </div>  
    </section>
    <section class="other">
        <section class="week">
            <h2>Rapport sur les 7 derniers jours</h2>
            <div class="nb-section">
                <div class="nb-container">
                    <p class="nb-container-title">Nouvelles ventes</p>
                    <b class="nb-container-content">{{ stats?.total_sales_on_7day }}</b>
                </div>
                <div class="nb-container">
                    <p class="nb-container-title">Revenus récoltés</p>
                    <b class="nb-container-content">{{ stats?.sum_sales_on_7day }} €</b>
                </div>
            </div>      
        </section>
        <section class="manage">
            <h2>Gérer les membres</h2>
            <button class="manage-member" routerLink="/members">Accéder au gestionnaire d'utilisateurs</button>
            <h2>Ajouter un film</h2>
            <button class="manage-movie" routerLink="/create">Ajouter</button>
        </section>
    </section>
</section>
