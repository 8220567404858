<app-searchbar *ngIf="isMobile"></app-searchbar>
<app-category></app-category>
<h2 class="phrase-res" *ngIf="isSearch">Résultats de votre recherche</h2>
<section id="sorting" *ngIf="isSearch">
    <button class="sort">
        <label for="alph-check" class="check"  (click)="onAlph();onSortAlph()"></label>
        <input type="checkbox" id="alph-check">
        <div class="btn alph-btn">
            <div class="front face">
                <img src="../../assets/icons/sort.svg" alt="Croissant" *ngIf="!isAlph">
                <p *ngIf="!isAlph">A-Z</p>
            </div>
            <div class="mid face"></div>
            <div class="back face">
                <img src="../../assets/icons/sort.svg" alt="Décroissant" *ngIf="isAlph">
                <p *ngIf="isAlph">A-Z</p>
            </div>
        </div>
    </button>
    <button class="sort">
        <label for="price-check" class="check"  (click)="onPrice();onSortPrice()"></label>
        <input type="checkbox" id="price-check">
        <div class="btn price-btn">
            <div class="front face">
                <img src="../../assets/icons/sort.svg" alt="Croissant" *ngIf="!isPrice">
                <p *ngIf="!isPrice">Prix</p>
            </div>
            <div class="mid face"></div>
            <div class="back face">
                <img src="../../assets/icons/sort.svg" alt="Décroissant" *ngIf="isPrice">
                <p *ngIf="isPrice">Prix</p>
            </div>
        </div>
    </button>
    <button class="sort">
        <label for="date-check" class="check" (click)="onDate();onSortDate()"></label>
        <input type="checkbox" id="date-check">
        <div class="btn date-btn">
            <div class="front face">
                <img src="../../assets/icons/sort.svg" alt="Croissant" *ngIf="!isDate">
                <p *ngIf="!isDate">Date</p>
            </div>
            <div class="mid face"></div>
            <div class="back face">
                <img src="../../assets/icons/sort.svg" alt="Décroissant" *ngIf="isDate">
                <p *ngIf="isDate">Date</p>
            </div>
        </div>
    </button>

</section>

<section id="results" *ngIf="!isEmpty && isSearch">
    <app-film-card *ngFor="let card of resMovies" [card]="card"></app-film-card>
</section>
<p class="empty" *ngIf="isEmpty">Aucun résultat</p>
<app-page-selector *ngIf="isSearch" [current]="currentPage" [total]="totalPage"></app-page-selector>


<section class="new" *ngIf="!isSearch">
    <section class="new-new">
        <h2>Nouveautés</h2>
        <div class="new-row" (scroll)="onScroll1($event)" #scroll1>
            <app-film-card *ngFor="let card of movies?.last_movies" [card]="card"></app-film-card>
            <div class="dummy">|||</div>
        </div>
        <button class="new-new-next start" *ngIf="scrollToLeft1" (click)="scroll1Start()"><span></span></button>
        <button class="new-new-next" *ngIf="scrollToRight1" (click)="scroll1End()"><span></span></button>
    </section>
    <section class="new-popular">
        <h2>Les plus populaires</h2>
        <div class="new-row" (scroll)="onScroll2($event)" #scroll2>
            <app-film-card *ngFor="let card of movies?.most_rating_movies" [card]='card[0].movie'></app-film-card>
            <div class="dummy">|||</div>
        </div>
        <button class="new-popular-next start" *ngIf="scrollToLeft2" (click)="scroll2Start()"><span></span></button>
        <button class="new-popular-next" *ngIf="scrollToRight2" (click)="scroll2End()"><span></span></button>
    </section>
</section>
<app-rgpd *ngIf="isRGPD" [(isRGPD)]="isRGPD"></app-rgpd>