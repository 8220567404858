<footer>
    <div class="foot-logo">
        <img src="../../assets/logo.svg" alt="">
    </div>
    <div class="links">
        <a routerLink="/privacy">Politique de confidentialité</a>
        <a routerLink="/agreement">Mentions Légales</a>
        <a href="https://api-limon.app-tricycle.com/api/doc">API</a>
        <a (click)="installApp()" aria-label="Télécharger l'app légère" class="pwa" *ngIf="showInstallButton">Télécharger l'app allégé</a>
    </div>
</footer>