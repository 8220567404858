<form (submit)="handleForm($event)" [formGroup]="" class="checkout">
    <img class="checkout-logo" src="assets/logo.svg" alt="">
    <p class="checkout-phrase">Paiement sécurisé avec <img src="https://ressources.blogdumoderateur.com/2018/07/Stripe-Logo-blue.png" alt=""> </p>
    <div class="checkout-col">
        <!-- display errors -->
        <p class="checkout-error">{{ cardErrors }}</p>
        <div id="card-element" #cardElement>
        <!-- a stripe element will be inserted here -->
        </div>
    </div>
    <div class="checkout-col">
        <p class="checkout-pr">Nom du compte: <span>{{ purchase?.customer }}</span></p>
        <p class="checkout-pr">Achat de <span>{{ purchase?.product }}</span></p>

    </div>
    <div class="checkout-row">
        <p class="checkout-pr">Montant: <span class="money">{{ amount }}€</span></p>
        <div class="checkout-row-button">
            <a class="checkout-cancel" (click)="onClear()" tabindex="0">Annuler</a>
            <button class="buy" type="submit">Payer {{ amount }} €</button>
        </div>
    </div>
    <div class="notif" *ngIf="loading">
        <span class="notif-loader"></span>
        loading ...
    </div>
</form>

